import React from 'react';
import { BrowserView, MobileView, TabletView } from 'react-device-detect';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Instagram = ({ data, children }) => {
  Instagram.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    children: PropTypes.element,
  };

  Instagram.defaultProps = {
    children: null,
  };

  const settings = {
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToScroll: 3,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="container-md">
        <div className="instalink-wrap">
          {data.InstaUrl.Url && (
            <div className="instalink">
              <a
                href={data.InstaUrl.Url}
                className="instagram-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.InstaUrl.Name && data.InstaUrl.Name}
              </a>
            </div>
          )}
        </div>
      </div>
      <BrowserView>
        <div className="instimage-wrapper">
          <Slider {...settings}>
            {data.InstaPosts &&
              data.InstaPosts.map(item => (
                <img
                  src={item.images.standard_resolution.url}
                  alt={item.user.full_name}
                  key={item.user.full_name}
                  className="slick-slide"
                />
              ))}
            {children}
          </Slider>
        </div>
      </BrowserView>
      <TabletView>
        <div className="instimage-wrapper">
          <Slider {...settings}>
            {data.InstaPosts &&
              data.InstaPosts.map(item => (
                <img
                  src={item.images.standard_resolution.url}
                  alt={item.user.full_name}
                  key={item.user.full_name}
                  className="slick-slide"
                />
              ))}
            {children}
          </Slider>
        </div>
      </TabletView>
      <MobileView>
        <div className="instimage-wrapper">
          {data.InstaPosts &&
            data.InstaPosts.slice(0, 3).map(item => (
              <div
                className="image"
                style={{
                  backgroundImage: `url(${item.images.standard_resolution.url})`,
                }}
              />
            ))}
          {children}
        </div>
      </MobileView>
    </React.Fragment>
  );
};
export default Instagram;
